<template>
  <div class="anthorize-login-bg">
    <el-dialog
      class="anthorize"
      title="授權登錄"
      :visible.sync="dialogVisible"
      center
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
    >
      <form :action="token" method="post" class="form" id="form">
        <span slot="footer" class="dialog-footer">
          <input type="text" :value="client_id" name="client_id" hidden />
          <input type="text" :value="redirect_uri" name="redirect_uri" hidden />
          <input
            type="text"
            :value="response_type"
            name="response_type"
            hidden
          />
          <input type="text" :value="grant_type" name="grant_type" hidden />
          <input type="text" :value="state" name="state" hidden />
          <el-form
            ref="LoginFormRef"
            :model="loginForm"
            label-width="0"
            :rules="LoginFormRules"
            class="login_form"
          >
            <el-form-item prop="username">
              <el-input
                v-model="loginForm.username"
                prefix-icon="el-icon-user"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="loginForm.password"
                prefix-icon="el-icon-lock"
                type="password"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="list-btn">
            <el-button
              @click=";(dialogVisible = false), (noDialogVisible = true)"
              type="danger"
              >取消</el-button
            >
            <el-button native-type="button" type="success" @click="submitForm"
              >授權登錄</el-button
            >
          </div>
        </span>
      </form>
    </el-dialog>
    <el-dialog
      class="anthorize"
      title="提示"
      :visible.sync="noDialogVisible"
      center
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
    >
      <span>你沒有權限</span>
    </el-dialog>
  </div>
</template>
<script>
import { loginUser } from '@/api/memberUser'
export default {
  data() {
    return {
      dialogVisible: true,
      noDialogVisible: false,
      client_id: this.$route.query.client_id || '',
      redirect_uri: this.$route.query.redirect_uri || '',
      response_type: this.$route.query.response_type || '',
      grant_type: this.$route.query.grant_type || '',
      state: this.$route.query.state,
      token: '',
      loginForm: {
        username: '',
        password: '',
      },
      LoginFormRules: {
        username: [{ required: true, message: '請輸入郵箱', trigger: 'blur' }],
        password: [{ required: true, message: '請輸入密碼', trigger: 'blur' }],
      },
    }
  },
  methods: {
    submitForm() {
      this.$refs['LoginFormRef'].validate(async (valid) => {
        if (valid) {
          const data = {
            username: this.loginForm.username,
            password: this.loginForm.password,
          }
          loginUser(data).then((res) => {
            if (res.result === 'success') {
              window.sessionStorage.setItem('token', res.data.access_token)
              let token =
                process.env.VUE_APP_BASE_API +
                'oauth/authorize?token=' +
                res.data.access_token
              document.getElementById('form').action = token
              document.getElementById('form').submit()
            }
          }).catch(()=>{
          this.$message({
            message: '登錄失敗',
            type: 'danger',
          })   
          })
        } else {
          this.$message({
            message: '登錄失敗',
            type: 'danger',
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
button {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.anthorize-login-bg {
  // min-height: 0;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  .anthorize {
    margin: 8% auto;
    width: 50%;
    // width: 100%;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    .form {
      text-align: center;
      .list-btn {
        margin-top: 50px;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .anthorize-login-bg {
    .anthorize {
      margin-top: 0;
      width: 70%;
    }
  }
}
</style>
